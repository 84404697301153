<template>
  <ContentRenderer
    v-if="page?.body"
    :value="page"
  />
</template>

<script setup lang="ts">
const route = useRoute()
const { t } = useI18n()

const { data: page } = await useAsyncData(`page-${route.path}`, () => queryContent(route.path).findOne())

if (!page.value) {
  throw createError({
    statusCode: 404,
    statusMessage: t('errors.pages.not_found'),
    fatal: true,
  })
}

useSeoMeta({
  title: () => page.value?.title || '',
  description: () => page.value?.description || '',
})
</script>
